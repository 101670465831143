import React from 'react';
import { Chip as ChipComponent } from '@janiscommerce/ui-web';
import BaseLink from 'components/Link';
import { getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';
import { stringify } from 'qs';
import { getUrlWithEndpointParameters } from 'utils';

const Chip = ({ children, clickable, linkField, rawData, endpointParameters, path, ...props }) => {
	const getUrl = () => {
		const pathParameters = getPathFiltersParameters(endpointParameters, rawData) || {};
		const queries = getQueryFiltersParameters(endpointParameters, rawData);
		let endpointUrl;

		endpointUrl = getUrlWithEndpointParameters(path, pathParameters);

		if (queries) {
			const linkHasQuery = endpointUrl.includes('?');
			endpointUrl = `${endpointUrl}${linkHasQuery ? '&' : '?'}${stringify(queries)}`;
		}
		return endpointUrl;
	};

	const validatePreview = !props.isPreviewChip ? '-' : '';

	const validChildren = children || children === 0 ? children : validatePreview;

	const parsedChildren = !Number.isNaN(Number(validChildren))
		? String(validChildren)
		: validChildren;

	const href = path ? getUrl() : linkField && rawData[linkField];
	const clickProp = href && {
		clickable: href
	};

	const Wrapper = href ? BaseLink : React.Fragment;
	return (
		<Wrapper href={href}>
			<ChipComponent className="chip-component" {...props} {...clickProp}>
				{parsedChildren}
			</ChipComponent>
		</Wrapper>
	);
};

export default Chip;
