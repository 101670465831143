import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { get as lodashGet, camelCase, startCase as titleCase, isArray } from 'lodash';
import DateHandler from 'libs/shared/DateHandler';
import { formatMapperTemplate, isObject } from 'utils';
import { translateHelperValue as translateHelper } from './translate';
import { isTranslationKey } from './string';
import { formatDate, processDate } from './dates';
import { head } from './array';
import { numberToCurrency } from './number';

export { camelCase, startCase as titleCase } from 'lodash';

momentDurationFormatSetup(moment);

export const addHashtag = str => `#${str}`;

export const upperCase = str => (typeof str === 'string' ? str.toUpperCase() : str);

export const lowerCase = str => (typeof str === 'string' ? str.toLowerCase() : str);

export const booleanToStatus = boolean =>
	boolean ? translateHelper('common.status.active') : translateHelper('common.status.inactive');

export const booleanToWord = boolean =>
	boolean ? translateHelper('common.boolean.yes') : translateHelper('common.boolean.no');

/** If no type is specified, maps an amount of hours into a string of weeks, days, hours, and minutes
 * @param {number} number - hours
 */
export const numberToTime = (number, props = {}) => {
	if (typeof number !== 'number') return null;

	const { type = 'hour', useFullDate = false } = props;

	/** Generates a string template with time measure translated initials dynamically
	 * @param {array} template string array with each time measure to be shown. Default: ['weeks', 'days', 'hours', 'minutes'];
	 * @returns {string} string template with each timeMeasure and their translated initials. Default: 'w[W] d[D] h[H] m[M]';
	 */
	const getMomentTemplate = (template = ['weeks', 'days', 'hours', 'minutes']) =>
		template
			.map(timeMeasure => {
				const translatedMoment = translateHelper(`common.moment.${timeMeasure}`).toLowerCase();
				return `${head(timeMeasure)}[${head(translatedMoment)}]`;
			})
			.join(' ');

	function customTemplate() {
		if (useFullDate) return getMomentTemplate();

		const hours = this.duration.asHours();
		if (type === 'minute') {
			// show minutes and hours
			const currentHours = hours < 2 ? 'hour' : 'hours';

			const currentMinutes = this.duration.minutes === 1 ? 'minute' : 'minutes';

			const hoursTemplate = `h [${translateHelper(`common.moment.${currentHours}`).toLowerCase()}]`;

			const minutesTemplate = `mm [${translateHelper(
				`common.moment.${currentMinutes}`
			).toLowerCase()}] `;

			if (hours < 24) return hours ? `${hoursTemplate} ${minutesTemplate}` : minutesTemplate;
		}

		if (hours === 1) return `h [${translateHelper('common.moment.hour').toLowerCase()}]`;

		if (hours < 24) return `h [${translateHelper('common.moment.hours').toLowerCase()}]`;

		if (hours === 168)
			// 1 week
			return `w [${translateHelper('common.moment.week').toLowerCase()}]`;

		if (hours % 168 === 0)
			// multiple of weeks
			return `w [${translateHelper('common.moment.weeks').toLowerCase()}]`;

		if (hours === 24)
			// 1 day
			return `d [${translateHelper('common.moment.day').toLowerCase()}]`;

		if (hours % 24 === 0)
			// multiple of days
			return `d [${translateHelper('common.moment.days').toLowerCase()}]`;

		return getMomentTemplate();
	}

	return moment
		.duration(number, `${type}s`)
		.format(customTemplate, { usePlural: false, trim: 'both' });
};

const statusError = (status, props = {}) => {
	const { type = 'string' } = props;

	const statusErrors = {
		400: 'badRequest',
		401: 'unauthorized',
		403: 'forbidden',
		404: 'notFound',
		500: 'internalServerError',
		empty: 'noRecordsYet',
		fetchItemsError: 'fetchItemsError',
		noItemsFound: 'noItemsFound',
		noResultsFound: 'noResultsFound',
		requiredFilter: 'hasRequiredFilters',
		requiredPlanificationFilter: 'hasRequiredFilters',
		noFiles: 'noFilesYet'
	};

	const key = `views.error.${statusErrors[status] || statusErrors[500]}`;

	return type === 'string' ? translateHelper(key) : key;
};

export const translate = (...args) => translateHelper(...args);

export const date = (value, props = {}) => {
	if (!value) return '';
	const { format = 'dd/MM/yyyy HH:mm', incomingFormat } = props;
	const processedValue = incomingFormat ? processDate(value, incomingFormat) : value;

	if (!DateHandler.isValid(processedValue)) return '';

	const formattedDate = DateHandler.format(processedValue, format);

	if (!formattedDate && DateHandler.isMomentFormat(format).isIncompatible)
		return formatDate(processedValue, format);

	return formattedDate;
};

export const prefix = (value, props = {}) => {
	if (!value && value !== 0) return '';

	const { value: prefixValue = '', translate: translatePrefix, addWhitespace } = props;

	const processedPrefix = translatePrefix ? translateHelper(prefixValue) : prefixValue;

	/** If addWhitespace is not defined, add a whitespace (or not) before the value according to the translate prop */
	const whitespace = typeof addWhitespace === 'undefined' ? translatePrefix : addWhitespace;

	const formattedValue = `${whitespace ? ' ' : ''}${value}`;

	return `${processedPrefix}${formattedValue}`;
};

export const currency = (value, props = {}, data = {}) => numberToCurrency(value, props, data);

export const fileSize = value => {
	if (typeof value === 'undefined' || value === null) return value;

	const measures = ['b', 'Kb', 'Mb', 'Gb'];

	let measureIndex = 0;
	let finalValue = value;

	while (finalValue >= 800 && measureIndex < measures.length - 1) {
		finalValue /= 1024;
		measureIndex++;
	}

	return `${Number(finalValue.toFixed(2))}${measures[measureIndex]}`;
};

export const suffix = (value, props = {}) => {
	if (!value && value !== 0) return '';

	const { value: suffixValue = '', translate: translateSuffix, addWhitespace } = props;

	const processedSuffix = translateSuffix ? translateHelper(suffixValue) : suffixValue;

	/** If addWhitespace is not defined, add a whitespace (or not) before the value according to the translate prop */
	const whitespace = typeof addWhitespace === 'undefined' ? translateSuffix : addWhitespace;

	const formattedValue = `${value}${whitespace ? ' ' : ''}`;

	return `${formattedValue}${processedSuffix}`;
};

export const join = (value, props = {}) => {
	if (!value || !Array.isArray(value)) return value;

	const { value: separator = ', ' } = props;

	return typeof separator === 'string' ? value.join(separator) : value;
};

export const arrayMap = (value, props = {}) => {
	if (!value || !Array.isArray(value)) return value;

	const { value: arrayMapValue = '' } = props;

	if (isObject(arrayMapValue))
		return value.map(data => formatMapperTemplate(arrayMapValue, data).trim());

	return arrayMapValue && typeof arrayMapValue === 'string'
		? value.map(data => lodashGet(data, arrayMapValue))
		: value;
};

export const template = (value, props = {}, data = {}, auxFormatter) =>
	props.fields && props.fields.some(field => lodashGet(data, isObject(field) ? field.name : field))
		? formatMapperTemplate(props, data, auxFormatter)
		: null;

export const addStaticValue = (value, props = {}) => {
	if (!props.value) return;

	return props.translate ? translateHelper(props.value) : props.value;
};

const mappers = {
	addHashtag,
	lowerCase,
	upperCase,
	booleanToStatus,
	booleanToWord,
	numberToTime,
	statusError,
	translate,
	date,
	prefix,
	currency,
	fileSize,
	suffix,
	join,
	arrayMap,
	template,
	camelCase,
	titleCase,
	addStaticValue
};

const getMapperData = (mapper = '') => ({
	mapperName: typeof mapper === 'string' ? mapper : mapper.name,
	mapperProps: typeof mapper === 'string' ? {} : mapper.props || {}
});

/**
 * Find mapper function
 * @param {string} name
 * @returns {string|object}
 */
export const findMapper = name => singleMapper => {
	const { mapperName } = getMapperData(singleMapper);
	return mapperName === name;
};

/**
 * Main Function for map values
 * @param {(string|array|object)} mapper - Mappers
 * @param {*} value - Any value
 * @param {object} data - Object for get additional values
 * @returns {*}
 */
export const map = (mapper, value, data = {}) => {
	if (!mapper) {
		if (typeof value !== 'string') return value;
		return mappers.translate(value, {});
	}

	const allMappers = Array.isArray(mapper) ? mapper : [mapper];

	return allMappers.reduce((acc, singleMapper) => {
		const { mapperName, mapperProps } = getMapperData(singleMapper);

		if (mapperName in mappers) return mappers[mapperName](acc, mapperProps, data);

		return acc;
	}, value);
};

/**
 * Function for map any value
 * @param {*} value - Any value
 * @param {(string|array|object)} mapper - Mappers
 * @param {object} currentData - Object for get additional values
 * @returns
 */
export const getValueMapped = (value, mapper, currentData) => {
	if (!mapper) return value;

	const isArrayMap = isArray(mapper)
		? mapper.some(currentMapper => currentMapper.name === 'arrayMap')
		: mapper.name === 'arrayMap';

	return isArray(value) && !isArrayMap
		? value.map(currentValue => getValueMapped(currentValue, mapper, currentData))
		: map(mapper, value, currentData);
};

/**
 * Make mappers arrat with translation modifications
 * @param {(string|array|object)} allMappers - Mappers
 * @param {*} value - Any value
 * @param {string} fieldName - Field name attribute
 * @returns {array} - Array Mappers
 */
const makeMappers = (allMappers, value, fieldName, makeTemplateValue) => {
	const translateMapperIdx = allMappers.findIndex(findMapper('translate'));
	const templateMapperIdx = allMappers.findIndex(findMapper('template'));
	const existsTranslateMapper = translateMapperIdx !== -1;
	const existsPrefixMapper = allMappers.some(findMapper('prefix'));

	if (templateMapperIdx !== -1) {
		const templateMapper = allMappers.find(findMapper('template'));
		if (templateMapper) {
			const enhancedTemplateMapper = {
				...templateMapper,
				props: { ...templateMapper.props, makeTemplateValue }
			};

			allMappers.splice(templateMapperIdx, 1, enhancedTemplateMapper);
		}
	}

	const existsMappers = existsTranslateMapper && existsPrefixMapper;

	if (existsMappers) return allMappers;

	if (existsTranslateMapper && !existsPrefixMapper) {
		if (isTranslationKey(value)) return allMappers;

		const defaultPrefixMapper = {
			name: 'prefix',
			props: { value: `views.fieldValue.${fieldName}.` }
		};

		allMappers.splice(translateMapperIdx, 0, defaultPrefixMapper);
	}

	return allMappers;
};
/**
 * Get processed value and translate wrapper for the cases that require it
 * @param {Object} config - config wieh necesayy property for make values
 * @param {(string|array|object)} config.mapper - Mappers
 * @param {*} config.value - Any value
 * @param {object} config.currentData - Object for get additional values
 * @param {boolean} config.hasTranslateWrapper - Has o not translate wrapper
 * @param {function} config.translateValueComponent - Function show value mapped and show modal translate
 * @param {function} config.makeTemplateValue - Function to create the template value with translated components interpolated
 * @returns {*}
 */
export const getMappedFieldValue = ({
	fieldName,
	mapper,
	value,
	currentData,
	hasTranslateWrapper = true,
	translateValueComponent,
	makeTemplateValue
}) => {
	if (!mapper) return value;

	const allMappers = Array.isArray(mapper) ? mapper : [mapper];

	const mappersArray = makeMappers(allMappers, value, fieldName, makeTemplateValue);

	const mappedValue = map(mappersArray, value, currentData);

	const translateMapper = mappersArray.find(findMapper('translate'));

	if (translateMapper && hasTranslateWrapper) {
		const prefixMapper = mappersArray.find(findMapper('prefix'));

		const key = prefixMapper ? map(prefixMapper, value) : value;

		return translateValueComponent(mappedValue, key);
	}

	return mappedValue;
};

export default mappers;
